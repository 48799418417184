import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import "./NavigationMobile.scss";
import { UserContext } from "../../contexts/UserContext";
import NavigationElement from "./NavigationElement";
import {useHistory, useLocation} from "react-router-dom";
import { getIgniteRoute } from "../../util/ignite/routes.utils";
import {IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS} from "../../constants/routes";


interface NavigationMobileProps {
    isLeaderView: boolean;
    currentClubId?: number;
    currentClubUrlFragment?: string;
    navigationShown: boolean;
    useClubDirectoryNav: boolean;
    useEdp44VirtualClubs: boolean;
    setNavigationShown: Function;
    hideAccountInfo: boolean;
    isClubIgniteEnabled: boolean;
}

function NavigationMobile(props: NavigationMobileProps): JSX.Element {
    const { t } = useTranslation('layout');
    const { user, logoutAll, logout } = useContext(UserContext);
    const [classes, setClasses] = useState("");

    const location = useLocation();
    const history = useHistory();
    const isIgnite = location.pathname.startsWith('/ignite');

    const getRoute = (key: IGNITE_ROUTE_KEY) => props.isClubIgniteEnabled ?
        getIgniteRoute(key, { ':clubId': String(props.currentClubId) }) :
        getIgniteRoute(key, { ':clubId': String(user?.isIgniteEnabled?.clubId) });

    const dashboardRoute = getRoute(IGNITE_ROUTE_KEY.DASHBOARD)
    const leaderDashboardRoute = getRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD)
    const manageMembersRoute = getRoute(IGNITE_ROUTE_KEY.LEADER_MANAGE_MEMBERS)

    async function onClickLogout() {
        try {
            logout().then(() => {
                logoutAll().then(() => {
                    history.push("/login", { 'referrer': null });
                });
            });
        } catch (e) {
            console.error("Error", e);
        }
    }

    useEffect(
        () => {
            let classes = "NavigationMobile";
            if (props.navigationShown) {
                classes += " NavigationMobile_Show";
            }
            setClasses(classes);
        }, [props.navigationShown]);

    return (
        <div className={classes}>
            {user === null && <div />}
            {user !== null &&
                <div className="NavigationMobile_Container">
                    {/* LEADER DASHBOARD */}
                    {props.isLeaderView && user.isIgniteEnabled &&
                        <NavigationElement setNavigationShown={props.setNavigationShown} to={leaderDashboardRoute}>
                            {t('navigation.toDoList')}
                        </NavigationElement>
                    }

                    {/* MANAGE CLUB */}
                    {props.isLeaderView && props.currentClubUrlFragment !== undefined && <>
                        <NavigationElement setNavigationShown={props.setNavigationShown} to={`/my-club/${props.currentClubUrlFragment}`}>
                            {t('navigation.manageClub')}
                        </NavigationElement>
                    </>}

                    {/* MANAGE MEMBERS */}
                    {props.isLeaderView && user.isIgniteEnabled &&
                        <NavigationElement setNavigationShown={props.setNavigationShown} to={manageMembersRoute}>
                            {t('navigation.manageMembers')}
                        </NavigationElement>
                    }

                    {/* MEMBER DASHBOARD */}
                    {!props.isLeaderView && user.isIgniteEnabled &&
                        <NavigationElement setNavigationShown={props.setNavigationShown} to={dashboardRoute}>
                            {t('navigation.myActivity')}
                        </NavigationElement>
                    }

                    {/* MY SCHEDULE */}
                    {!props.isLeaderView &&
                        <NavigationElement setNavigationShown={props.setNavigationShown}
                                           to={"/my-events"}>
                            {t('navigation.mySchedule')}
                        </NavigationElement>
                    }

                   {/* VIRTUAL CLUBS */}
                    {!props.isLeaderView && props.useEdp44VirtualClubs &&
                        <NavigationElement setNavigationShown={props.setNavigationShown}
                            to={"/virtual-clubs"}>
                            {t('navigation.virtualClubs')}
                        </NavigationElement>
                    }
                    {/* FIND AN ACTIVITY */}
                    {!props.isLeaderView &&
                        <NavigationElement setNavigationShown={props.setNavigationShown}
                                           to={"/find-an-event"}>
                            {t('navigation.findAnActivity')}
                        </NavigationElement>
                    }

                    {/* CLUB DIRECTORY */}
                    {!props.isLeaderView && props.useClubDirectoryNav &&
                        <NavigationElement setNavigationShown={props.setNavigationShown}
                                           to={"/clubs"}>
                            {t('navigation.clubDirectory')}
                        </NavigationElement>
                    }

                    {!props.hideAccountInfo && <>
                        <NavigationElement setNavigationShown={props.setNavigationShown}
                                           to={IGNITE_ROUTE_PATTERNS.MEMBER_INFO.replace(':clubId', String(props.currentClubId || '' ))}>
                            {t('navigation.profile')}
                        </NavigationElement>
                        <NavigationElement setNavigationShown={props.setNavigationShown}
                            clickHandler={onClickLogout}>
                            {t('navigation.logout')}
                        </NavigationElement>
                    </>}
                </div>}
        </div>
    );
}

export default NavigationMobile;