import './index.scss';
import './styles/fonts.scss';
import './styles/common.scss';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AboutPage from './pages/About/AboutPage';
import { AcblFaq } from './pages/acblFaq/AcblFaq';
import AcblLandingPage from './pages/acblLanding/AcblLandingPage';
import { AcblLaunchSweepstakes } from './pages/acblLaunchSweepstakes/AcblLaunchSweepstakes';
import { AcblPromptContinue } from './pages/acblPromptContinue/AcblPromptContinue';
import AcblRegistrationComplete from './pages/acblRegistrationComplete/AcblRegistrationComplete';
import AcblRegistrationWizard from './pages/acblRegistration/registrationWizard/AcblRegistrationWizard';
import CenteneLandingPage from './pages/centeneLanding/CenteneLandingPage';
import CenteneRegistartionWizard from './pages/memberRegistration/memberSelectPayer/CenteneRegistrationWizard';
import Club from './pages/clubDirectory/Club';
import ClubAgreementPage from './pages/clubAgreement/ClubAgreementPage';
import ClubDirectory from './pages/clubDirectory/ClubDirectory';
import ClubFinderPage from './pages/directToConsumerLanding/components/ClubFinderPage';
import ClubDirectoryRegistrationPage from './pages/clubDirectory/registration/ClubDirectoryRegistrationPage';

import ClubInvitationPage from './pages/clubInvitation/ClubInvitation';
import ClubLeaderFaqPage from './pages/clubLeaderFaq/ClubLeaderFaqPage';
import ClubLeaderLandingPage from './pages/clubLeaderLanding/ClubLeaderLanding';
import ClubLeaderTermsOfUsePage from './pages/clubLeaderTermsOfUse/ClubLeaderTermsOfUsePage';
import ClubLeaderViewEvent from './pages/clubLeaderViewEvent/ClubLeaderViewEvent';
import ClubMemberLandingPage from './pages/clubMemberLanding/ClubMemberLandingPage';
import ClubMemberLandingPageWithRouteParams from './pages/clubMemberLanding/ClubMemberLandingPageUseRouteParams';
import ClubMemberRegistrationWizard from './pages/clubMemberRegistration/registrationWizard/ClubMemberRegistrationWizard';
import ClubPage from './pages/club/ClubPage';
import ConditionalRedirectRoute from './router/ConditionalRedirectRoute';
import { CustomEnrollmentAgreements } from './pages/customEnrollmentAgreements/CustomEnrollmentAgreements';
import CustomEnrollmentComplete from './pages/customEnrollmentComplete/CustomEnrollmentComplete';
import { CustomEnrollmentFaq } from './pages/customEnrollmentFaq/CustomEnrollmentFaq';
import CustomEnrollmentLandingPage from './pages/customEnrollmentLanding/CustomEnrollmentLandingPage';
import DirectToConsumerLandingPage from './pages/directToConsumerLanding/DirectToConsumerLandingPage';
import EditClubPage from './pages/editClub/EditClubPage';
import EditEventPage from './pages/editEvent/EditEventPage';
import EventImportWizardPage from './pages/eventImportWizard/EventImportWizardPage';
import EventLandingPage from './pages/eventLanding/EventLanding';
import Events from './pages/events/Events';
import FindAClubPage from './pages/findAClub/FindAClubPage';
import FindAnEventPage from './pages/findAnEvent/FindAnEventPage';
import HomePage from './pages/home/HomePage';
import LeaderRoute from './router/LeaderRoute';
import LoginPage from './pages/login/LoginPage';
import MasqueradePage from './pages/masquerade/MasqueradePage';
import MemberCreateAccountPage from './pages/memberCreateAccount/MemberCreateAccountPage';
import MemberLandingPage from './pages/memberLanding/MemberLandingPage';
import MemberRegistrationWizard from './pages/memberRegistration/memberSelectPayer/MemberRegistrationWizard';
import MemberSelectPayerPage from './pages/memberRegistration/memberSelectPayer/MemberSelectPayerPage';
import MyClubPage from './pages/myClub/MyClubPage';
import MyEventsPage from './pages/myEvents/MyEventsPage';
import NewMemberRegistrationWizard from './pages/newMemberRegistration/NewMemberRegistrationWizard';
import NotFoundPage from './pages/notFound/NotFoundPage';
import OptumMemberLandingPage from './pages/optumMemberLanding/OptumMemberLandingPage';
import OptumMemberRegistrationWizard from './pages/optumMemberRegistration/registrationWizard/OptumMemberRegistrationWizard';
import PasswordResetConfirmPage from './pages/passwordReset/PasswordResetConfirm';
import PasswordResetPage from './pages/passwordReset/PasswordReset';
import PayerMemberLandingPage from './pages/payerMemberLanding/PayerMemberLandingPage';
import PayerMemberRegistrationWizard from './pages/payerMemberRegistration/registrationWizard/PayerMemberRegistrationWizard';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import PrivateRoute from './router/PrivateRoute';
import TermsOfUsePage from './pages/termsOfUse/TermsOfUsePage';
import UnregisteredRoute from './router/UnregisteredRoute';
import { UserContext } from './contexts/UserContext';
import VerifyAcblEmail from './pages/verifyAcblEmail/VerifyAcblEmail';
import VerifyEmailComplete from './pages/verifyEmail/VerifyEmailComplete';
import VerifyEmailPage from './pages/verifyEmail/VerifyEmail';
import { withLayout } from './components/layout/Layout';
import { withNewLayout } from './components/layout/NewLayout';

import { useFeatureFlag } from './services/FeatureFlagService';
import {
  USE_CLUB_DIRECTORY_NAV,
  USE_EDP_44_VIRTUAL_CLUBS,
  USE_USGA_ROUTE,
} from './constants/flags';
import LegacyVirtualClubsPage from './pages/virtualClubs_legacy/LegacyVirtualClubsPage';
import VirtualClubsLandingPage from './pages/virtualClubs/VirtualClubsLandingPage';
import VirtualClubsCategoryPage from './pages/virtualClubs/VirtualClubsCategoryPage';
import VirtualClubsClubDetailPage from './pages/virtualClubs/VirtualClubsClubDetailPage';
import LoggedOutVirtualClubsRegistrationPage from './pages/virtualClubs/LoggedOutVirtualClubsRegistrationPage';
import { storeRegistrationParametersToSession } from './util/Util';

const TermsOfUsePageWithLayout = withLayout(TermsOfUsePage);
const PrivacyPolicyPageWithLayout = withLayout(PrivacyPolicyPage);
const ClubLeaderTermsOfUsePageWithLayout = withLayout(ClubLeaderTermsOfUsePage);
const AboutPageWithLayout = withLayout(AboutPage);
const ClubAgreementPageWithLayout = withLayout(ClubAgreementPage);
const LoginPageWithLayout = withLayout(LoginPage);
const PasswordResetPageWithLayout = withLayout(PasswordResetPage);
const PasswordResetConfirmPageWithLayout = withLayout(PasswordResetConfirmPage);
const VerifyAcblEmailWithLayout = withLayout(VerifyAcblEmail);
const ClubLeaderLandingPageWithLayout = withLayout(ClubLeaderLandingPage);
const ClubInvitationPageWithLayout = withLayout(ClubInvitationPage);
const EventLandingPageWithLayout = withLayout(EventLandingPage);
const MemberLandingPageWithLayout = withLayout(MemberLandingPage);
const ClubMemberLandingPageWithLayout = withLayout(ClubMemberLandingPage);
const ClubMemberLandingPageUseRouteParamsWithLayout = withLayout(ClubMemberLandingPageWithRouteParams);
const OptumMemberLandingPageWithLayout = withLayout(OptumMemberLandingPage);
const CenteneLandingPageWithLayout = withLayout(CenteneLandingPage);
const AcblLandingPageWithLayout = withLayout(AcblLandingPage);
const EventsWithLayout = withLayout(Events);
const PayerMemberLandingPageWithLayout = withLayout(PayerMemberLandingPage);
const PayerMemberRegistrationWizardWithLayout = withLayout(
  PayerMemberRegistrationWizard,
);
const CenteneRegistartionWizardWithLayout = withLayout(
  CenteneRegistartionWizard,
);
const MemberRegistrationWizardWithLayout = withLayout(MemberRegistrationWizard);
const ClubMemberRegistrationWizardWithLayout = withLayout(
  ClubMemberRegistrationWizard,
);
const OptumMemberRegistrationWizardWithLayout = withLayout(
  OptumMemberRegistrationWizard,
);
const MemberSelectPayerPageWithLayout = withLayout(MemberSelectPayerPage);
const MemberCreateAccountPageWithLayout = withLayout(MemberCreateAccountPage);
const NewMemberRegistrationWizardWithLayout = withLayout(
  NewMemberRegistrationWizard,
);
const AcblRegistrationWizardWithLayout = withLayout(AcblRegistrationWizard);
const AcblPromptContinueWithLayout = withLayout(AcblPromptContinue);
const AcblRegistrationCompleteWithLayout = withLayout(AcblRegistrationComplete);
const AcblFaqWithLayout = withLayout(AcblFaq);
const AcblLaunchSweepstakesWithLayout = withLayout(AcblLaunchSweepstakes);
const CustomEnrollmentLandingPageWithLayout = withLayout(
  CustomEnrollmentLandingPage,
);
const CustomEnrollmentFaqWithLayout = withLayout(CustomEnrollmentFaq);
const CustomEnrollmentCompleteWithLayout = withLayout(CustomEnrollmentComplete);
const CustomEnrollmentAgreementsWithLayout = withLayout(
  CustomEnrollmentAgreements,
);
const HomePageWithLayout = withLayout(HomePage);
const VerifyEmailPageWithLayout = withLayout(VerifyEmailPage);
const VerifyEmailCompleteWithLayout = withLayout(VerifyEmailComplete);
const MasqueradePageWithLayout = withLayout(MasqueradePage);
const ClubPageWithLayout = withLayout(ClubPage);
const EventImportWizardPageWithLayout = withLayout(EventImportWizardPage);
const MyClubPageWithLayout = withLayout(MyClubPage);
const ClubLeaderFaqPageWithLayout = withLayout(ClubLeaderFaqPage);
const EditClubPageWithLayout = withLayout(EditClubPage);
const EditEventPageWithLayout = withLayout(EditEventPage);
const ClubLeaderViewEventWithLayout = withLayout(ClubLeaderViewEvent);
const MyEventsPageWithLayout = withLayout(MyEventsPage);
const VirtualClubsLandingPageWithLayout = withLayout(VirtualClubsLandingPage);
const VirtualClubsCategoryPageWithLayout = withLayout(VirtualClubsCategoryPage);
const VirtualClubsClubDetailPageWithLayout = withLayout(
  VirtualClubsClubDetailPage,
);
const LoggedOutVirtualClubsRegistrationPageWithLayout = withLayout(
  LoggedOutVirtualClubsRegistrationPage,
);
const LegacyVirtualClubsPageWithLayout = withLayout(LegacyVirtualClubsPage);

const FindAClubPageWithLayout = withLayout(FindAClubPage);
const ClubDirectoryWithNewLayout = withNewLayout(ClubDirectory);
const ClubWithNewLayout = withNewLayout(Club);
const FindAnEventPageWithLayout = withLayout(FindAnEventPage);
const NotFoundPageWithLayout = withLayout(NotFoundPage);
const DirectToConsumerLandingPageWithLayout = withLayout(
  DirectToConsumerLandingPage,
);
const ClubFinderPageWithLayout = withLayout(ClubFinderPage);
const ClubDirectoryRegistrationPageWithLayout = withLayout(
  ClubDirectoryRegistrationPage,
);

export default function AuthenticatedRouter(): JSX.Element {
  const { user } = useContext(UserContext);

  const useClubDirectoryNav = useFeatureFlag(USE_CLUB_DIRECTORY_NAV, true);
  const useEdp44VirtualClubs = useFeatureFlag(USE_EDP_44_VIRTUAL_CLUBS, true);
  const useUSGARoute = useFeatureFlag(USE_USGA_ROUTE, true);

  return (
    <>
      <Switch>
        <Route
          exact
          path="/terms-of-use"
          //TODO: leaving this for better tracking of code cleanup for TOS components (EDP-637)
          // component={TermsOfUsePageWithLayout}
          render={() => {
            window.location.href = 'https://hellogrouper.com/app-terms-of-use/';
            return null;
          }}
        />
        <Route
          exact
          path="/privacy-policy"
          //TODO: leaving this for better tracking of code cleanup for TOS components (EDP-637)
          // component={PrivacyPolicyPageWithLayout}
          render={() => {
            window.location.href = 'https://hellogrouper.com/privacy-policy/';
            return null;
          }}
        />
        <Route
          exact
          path="/club-leader-terms-of-use"
          //TODO: leaving this for better tracking of code cleanup for TOS components (EDP-637)
          // component={ClubLeaderTermsOfUsePageWithLayout}
          render={() => {
            window.location.href =
              'https://hellogrouper.com/club-leader-terms-of-use';
            return null;
          }}
        />
        <Route 
          exact 
          path="/about" 
          render={() => {
                window.location.href = 'https://hellogrouper.com/get-help';
              return null;
            }}
        />
        <Route
          exact
          path="/club-agreement"
          //TODO: leaving this for better tracking of code cleanup for TOS components (EDP-637)
          // component={ClubAgreementPageWithLayout}
          render={() =>
            (window.location.href = 'https://hellogrouper.com/club-agreement')
          }
        />
        <Route exact path="/login" component={LoginPageWithLayout} />
        <Route
          exact
          path="/password-reset"
          component={PasswordResetPageWithLayout}
        />
        <Route
          path="/password-reset-confirm"
          component={PasswordResetConfirmPageWithLayout}
        />
        <Route
          path="/verify-acbl-email"
          component={VerifyAcblEmailWithLayout}
        />
        <Route
          path="/club-leader/:deputyLeaderInviteUrlFragment?"
          component={ClubLeaderLandingPageWithLayout}
        />
        <PrivateRoute
          exact
          path="/club-invitation/:inviteToken?"
          component={ClubInvitationPageWithLayout}
        />
        <Route
          path="/event/:eventUrlFragment?/:source?"
          component={EventLandingPageWithLayout}
        />
        <Route exact path="/member" component={MemberLandingPageWithLayout} />
        <Route
          exact
          path="/club-member"
          component={ClubMemberLandingPageWithLayout}
        />\
       <Route
          exact
          path="/club-member/:utmSource/:utmMedium/:utmCampaign"
          component={ClubMemberLandingPageUseRouteParamsWithLayout}
        />
        <Route
          exact
          path="/get-active"
          component={OptumMemberLandingPageWithLayout}
        />
        <Route
          exact
          path="/wellcare"
          render={() => {
            window.location.href = 'https://hellogrouper.com';
            return null;
          }}
        />
        <Route exact path="/acbl" component={AcblLandingPageWithLayout} />
        <Route exact path="/events" component={EventsWithLayout} />

        <Route
          exact
          path="/welcome/allina-health"
          render={({ history }) => {
            history.replace('/welcome/allina-health-aetna');
            return null;
          }}
        />
        <Route
          exact
          path="/registration/allina-health"
          render={({ history }) => {
            history.replace('/registration/allina-health-aetna');
            return null;
          }}
        />

        <Route
          exact
          path="/registration/:payerSlug"
          component={PayerMemberRegistrationWizardWithLayout}
        />
        <Route
          exact
          path="/welcome/:payerSlug"
          component={PayerMemberLandingPageWithLayout}
        />
        <Route
          exact
          path="/registration/:payerSlug"
          component={PayerMemberRegistrationWizardWithLayout}
        />
        <UnregisteredRoute
          exact
          path="/member/wellcare-registration"
          component={CenteneRegistartionWizardWithLayout}
        />
        <UnregisteredRoute
          exact
          path="/member/new-registration"
          component={MemberRegistrationWizardWithLayout}
        />
        <UnregisteredRoute
          exact
          path="/club-member/new-registration"
          component={ClubMemberRegistrationWizardWithLayout}
        />
        <UnregisteredRoute
          exact
          path="/get-active/new-registration"
          component={OptumMemberRegistrationWizardWithLayout}
        />
        <UnregisteredRoute
          exact
          path="/member/select-payer"
          component={MemberSelectPayerPageWithLayout}
        />
        <UnregisteredRoute
          exact
          path="/member/create-account"
          component={MemberCreateAccountPageWithLayout}
        />
        <Route
          exact
          path="/register-member"
          component={DirectToConsumerLandingPageWithLayout}
        />
        <Route
          exact
          path="/register-member/byoc"
          component={ClubFinderPageWithLayout}
        />
        {useClubDirectoryNav && (
          <Route
            exact
            path="/register-member/club-interest/:clubUrlFragment"
            component={ClubDirectoryRegistrationPageWithLayout}
          />
        )}
        <Route
          exact
          path="/register/:payer"
          component={NewMemberRegistrationWizardWithLayout}
        />
        <Route
          exact
          path="/acbl/new-registration"
          component={AcblRegistrationWizardWithLayout}
        />
        {user && user.isIncompleteRegistration && (
          <Route
            exact
            path="/acbl/prompt-continue"
            component={AcblPromptContinueWithLayout}
          />
        )}
        <Route
          exact
          path="/acbl/registration-complete"
          component={AcblRegistrationCompleteWithLayout}
        />
        <Route exact path="/acbl/faq" component={AcblFaqWithLayout} />
        <Route
          exact
          path="/acbl-launch-sweepstakes"
          component={AcblLaunchSweepstakesWithLayout}
        />
        {/* Hardcoded 1-off association URL redirects for custom enrollment aka "Vanity URLs" */}
        <Route
          exact
          path="/aaca"
          render={({ history }) => {
            history.replace('/p/aaca' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/ama"
          render={({ history }) => {
            history.replace('/p/ama' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/mga"
          render={({ history }) => {
            history.replace('/p/mga' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/myjongg"
          render={({ history }) => {
            history.replace('/p/myjongg' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/rhs"
          render={({ history }) => {
            history.replace('/p/rhs' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/usbc"
          render={({ history }) => {
            history.replace('/p/usbc' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/uschess"
          render={({ history }) => {
            history.replace('/p/uschess' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/durhamrec"
          render={({ history }) => {
            history.replace('/p/durhamrec' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/aba"
          render={({ history }) => {
            history.replace('/p/aba' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/npc"
          render={({ history }) => {
            history.replace('/p/npc' + history.location.search)
            return null;
          }}
        />
        {useUSGARoute && (
          <Route
            exact
            path="/usga"
            render={({ history }) => {
              history.replace('/p/usga' + history.location.search)
              return null;
            }}
          />
        )}
        <Route
          exact
          path="/groupworks"
          render={({ history }) => {
            history.replace('/p/groupworks' + history.location.search)
            return null;
          }}
        />
        <Route
          exact
          path="/p/:customEnrollmentShortCode"
          component={CustomEnrollmentLandingPageWithLayout}
        />
        <Route
          exact
          path="/p/:customEnrollmentShortCode/register"
          component={NewMemberRegistrationWizardWithLayout}
        />
        <Route
          exact
          path="/p/:customEnrollmentShortCode/faq"
          component={CustomEnrollmentFaqWithLayout}
        />
        <Route
          exact
          path="/p/:customEnrollmentShortCode/enrollment-complete"
          component={CustomEnrollmentCompleteWithLayout}
        />
        <Route
          exact
          path="/p/:customEnrollmentShortCode/agreements/:agreementSlug"
          component={CustomEnrollmentAgreementsWithLayout}
        />
        <PrivateRoute exact path="/" component={HomePageWithLayout} />
        <Route path="/verify-email" component={VerifyEmailPageWithLayout} />
        <PrivateRoute
          path="/verify-email-complete"
          component={VerifyEmailCompleteWithLayout}
        />
        <Route exact path="/masquerade" component={MasqueradePageWithLayout} />
        <PrivateRoute
          path="/club/:clubUrlFragment?"
          component={ClubPageWithLayout}
        />
        <LeaderRoute
          exact
          path="/import-events/:clubUrlFragment"
          component={EventImportWizardPageWithLayout}
        />
        <LeaderRoute
          exact
          path="/my-club/:clubUrlFragment?"
          component={MyClubPageWithLayout}
        />
        <LeaderRoute
          exact
          path="/club-leader-faq"
          component={ClubLeaderFaqPageWithLayout}
        />
        <PrivateRoute
          exact
          path="/edit-club/:clubUrlFragment?"
          component={EditClubPageWithLayout}
        />
        <LeaderRoute
          exact
          path="/add-activity/:eventOrClubUrlFragment?"
          component={EditEventPageWithLayout}
        />
        <LeaderRoute
          exact
          path="/edit-activity/:eventOrClubUrlFragment?"
          component={EditEventPageWithLayout}
        />
        <LeaderRoute
          path="/event-details/:eventUrlFragment?"
          component={ClubLeaderViewEventWithLayout}
        />
        <PrivateRoute
          exact
          path="/my-events"
          component={MyEventsPageWithLayout}
        />
        <PrivateRoute
          exact
          path="/find-a-club"
          component={FindAClubPageWithLayout}
        />
        <PrivateRoute
          exact
          path="/find-an-event"
          component={FindAnEventPageWithLayout}
        />

        {useClubDirectoryNav && (
          <PrivateRoute exact path="/clubs" component={ClubDirectoryWithNewLayout} />
        )}
        {useClubDirectoryNav && (
          <PrivateRoute exact path="/clubs/:clubId" component={ClubWithNewLayout} />
        )}

        {useEdp44VirtualClubs && (
          <ConditionalRedirectRoute
            exact
            path="/virtual-clubs"
            loggedInComponent={VirtualClubsLandingPageWithLayout}
            loggedOutComponent={LoggedOutVirtualClubsRegistrationPageWithLayout}
            loggedOutRedirectUrl="https://hellogrouper.com/welcome-to-grouper/"
            forwardSearchParameters={true}
          />
        )}
        {useEdp44VirtualClubs && (
          <PrivateRoute
            exact
            path="/virtual-clubs/categories/:termId"
            component={VirtualClubsCategoryPageWithLayout}
          />
        )}
        {useEdp44VirtualClubs && (
          <PrivateRoute
            exact
            path="/virtual-clubs/club/:clubUrlFragment"
            component={VirtualClubsClubDetailPageWithLayout}
          />
        )}

        <PrivateRoute component={NotFoundPageWithLayout} />
        <UnregisteredRoute to="/login" />
      </Switch>
    </>
  );
}
