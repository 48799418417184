import React, {useContext, useEffect, useRef} from "react";
import {RouteComponentProps, Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./OptumMemberLandingPage.scss";
import "../clubLeaderLanding/ClubLeaderLanding.scss";
import Button, {ButtonStyles} from "../../components/common/Button";
import {UserContext} from "../../contexts/UserContext";
import { storeRegistrationParametersToSession } from "../../util/Util";
import {DoubleArrow, ExpandMore} from "@material-ui/icons";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

/**
 * A page with static content that contains a link to register as a member.
 */
export default function OptumMemberLandingPage(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {history} = props;
    const {user} = useContext(UserContext);
    const whoWeAreRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        storeRegistrationParametersToSession(history.location);
    }, []);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        storeRegistrationParametersToSession(history.location);
    }, []);

    const onClickSignUpButton = () => {
        history.push("/get-active/new-registration");
    }

    const onClickMoreInfoButton = () => {
        window.scrollTo({
            top: whoWeAreRef.current!.offsetTop,
            behavior: "smooth"
        })
    }

    return (
        <div className="OptumMemberLandingPage">
            <h1 className={"OptumMemberLandingPage_Title"}>
                {t("optumMemberLandingPage.title")}
            </h1>
            <div className={"OptumMemberLandingPage_Section1"}>
                <img className={"OptumMemberLandingPage_BannerImage OptumMemberLandingPage_BannerImageDesktop"}
                     src={require("../../assets/get-active-banner-desktop.jpg")}/>
                <img className={"OptumMemberLandingPage_BannerImage OptumMemberLandingPage_BannerImageMobile"}
                     src={require("../../assets/get-active-banner-mobile.jpg")}/>
                <div className={"OptumMemberLandingPage_Section1_Panel"}>
                    <div className={"OptumMemberLandingPage_SignUpSubsection"}>
                        <div className={"OptumMemberLandingPage_SignUpSubsection_AboveText"}>
                            {t("optumMemberLandingPage.section1.signUpAboveText")}
                        </div>
                        <div className={"OptumMemberLandingPage_SignUpSubsection_ButtonPanel"}>
                            <Button text={t('optumMemberLandingPage.section1.signUpLinkText')}
                                    className={"OptumMemberLandingPage_SignUpSubsection_SignUpButton"}
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    disabled={user !== null}
                                    clickHandler={onClickSignUpButton}
                            />
                            <Button text={t("optumMemberLandingPage.section1.moreInfoLinkText")}
                                    className={"OptumMemberLandingPage_SignUpSubsection_MoreInfoButton"}
                                    buttonStyle={ButtonStyles.UnfilledWithBorder}
                                    clickHandler={onClickMoreInfoButton}
                            />
                        </div>
                        <div className={"OptumMemberLandingPage_SignUpSubsection_Below"}>
                            <span>{t("optumMemberLandingPage.section1.signUpBelowText")}</span>
                            <Link to={"/login"} className={"OptumMemberLandingPage_SignUpSubsection_Below_Link"}>
                                {t("optumMemberLandingPage.section1.signUpBelowTextLink")}
                            </Link>
                        </div>
                    </div>
                    <div className={"OptumMemberLandingPage_Section1_DividerSection"}>
                        <div className={"OptumMemberLandingPage_Section1_Divider"}/>
                        <ExpandMore className={"OptumMemberLandingPage_Section1_DividerIcon"} />
                    </div>
                    <div className={"OptumMemberLandingPage_GymSubsection"}>
                        <div className={"OptumMemberLandingPage_GymSubsection_AboveText"}>
                            {t("optumMemberLandingPage.section1.gymAboveText")}
                        </div>
                        <div className={"OptumMemberLandingPage_GymSubsection_BelowText"}>
                            {t("optumMemberLandingPage.section1.gymBelowText")}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"OptumMemberLandingPage_Section2"}>
                <h1 className={"OptumMemberLandingPage_Section2_Title"}>
                    {t("optumMemberLandingPage.section2.title")}
                </h1>
                <Carousel autoPlay
                          infiniteLoop
                          showThumbs={false}
                          interval={5000}
                          className={"OptumMemberLandingPage_TestimonyPanel"}>
                    <Testimony number={1} />
                    <Testimony number={2} />
                    <Testimony number={3} />
                </Carousel>
            </div>
            <div className={"OptumMemberLandingPage_Section3"} ref={whoWeAreRef}>
                <h1 className={"OptumMemberLandingPage_Section3_Title"}>
                    {t("optumMemberLandingPage.section3.title")}
                </h1>
                <div className={"OptumMemberLandingPage_Section3_Panel"}>
                    <div className={"OptumMemberLandingPage_Section3_LeftColumn"}>
                        <div className={"OptumMemberLandingPage_Section3_Subtitle"}>
                            {t("optumMemberLandingPage.section3.subtitle")}
                        </div>
                        <div className={"OptumMemberLandingPage_Section3_Content"}>
                            {t("optumMemberLandingPage.section3.content")}
                        </div>
                    </div>
                    <div className={"OptumMemberLandingPage_Section3_RightColumn"}>
                        <img src={require("../../assets/get-active-who-we-are-desktop.jpg")}
                             className={"OptumMemberLandingPage_Section3_Image OptumMemberLandingPage_Section3_ImageDesktop"} />
                        <img src={require("../../assets/get-active-who-we-are-mobile.jpg")}
                             className={"OptumMemberLandingPage_Section3_Image OptumMemberLandingPage_Section3_ImageMobile"} />
                    </div>
                </div>
            </div>
            <div className={"OptumMemberLandingPage_Section4"}>
                <h1 className={"OptumMemberLandingPage_Section4_Title"}>
                    {t("optumMemberLandingPage.section4.title")}
                </h1>
                <div className={"OptumMemberLandingPage_Section4_Steps"}>
                    <HowItWorksStep number={1} />
                    <HowItWorksStep number={2} />
                    <HowItWorksStep number={3} />
                </div>
            </div>
            <div className={"OptumMemberLandingPage_Section5"}>
                <h1 className={"OptumMemberLandingPage_Section5_Title"}>
                    {t("optumMemberLandingPage.section5.title")}
                </h1>
                <div className={"OptumMemberLandingPage_Section5_Subtitle"}>
                    {t("optumMemberLandingPage.section5.subtitle")}
                </div>
                <div className={"OptumMemberLandingPage_Section5_Content"}>
                    {t("optumMemberLandingPage.section5.content")}
                </div>
                <div className={"OptumMemberLandingPage_Section5_Clubs"}>
                    <LandingPageClub number={1}/>
                    <LandingPageClub number={2}/>
                    <LandingPageClub number={3}/>
                </div>
            </div>
            <div className={"OptumMemberLandingPage_Section6"}>
                <Button text={t("optumMemberLandingPage.section6.signUpLinkText")}
                        className={"OptumMemberLandingPage_SignUpButton"}
                        buttonStyle={ButtonStyles.FilledPrimary}
                        disabled={user !== null}
                        clickHandler={onClickSignUpButton}
                />
                <Link to={"/about"}
                      target={"_blank"}
                      className={"OptumMemberLandingPage_FAQButton"}>
                    {t("optumMemberLandingPage.section6.faqLinkText")}
                    <DoubleArrow className={"OptumMemberLandingPage_FAQButton_Icon"} />
                </Link>
            </div>
        </div>
    );
}

function Testimony({number}: {number: number}): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <div className={"OptumMemberLandingPage_Testimony"}>
            <img src={require(`../../assets/get-active-testimony-${number}.jpg`)}
                 className={"OptumMemberLandingPage_Testimony_Image"}/>
            <div className={"OptumMemberLandingPage_Testimony_Text"}>
                {t(`optumMemberLandingPage.section2.testimony${number}`)}
            </div>
        </div>
    )
}

function HowItWorksStep({number}: {number: number}): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <>
            <div className={"OptumMemberLandingPage_Section4_Step_Label"}>
                {t(`optumMemberLandingPage.section4.step${number}.label`)}
            </div>
            <div className={"OptumMemberLandingPage_Section4_Step_Title"}>
                {t(`optumMemberLandingPage.section4.step${number}.title`)}
            </div>
            <div className={"OptumMemberLandingPage_Section4_Step_Content"}>
                {t(`optumMemberLandingPage.section4.step${number}.content`)}
            </div>
        </>
    )
}

function LandingPageClub({number}: {number: number}): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <div className={"OptumMemberLandingPage_Club"}>
            <img src={require(`../../assets/get-active-club-${number}.jpg`)}
                 className={"OptumMemberLandingPage_Club_Image"}/>
            <div className={"OptumMemberLandingPage_Club_Title"}>
                {t(`optumMemberLandingPage.section5.club${number}.title`)}
            </div>
            <div className={"OptumMemberLandingPage_Club_Content"}>
                {t(`optumMemberLandingPage.section5.club${number}.content`)}
            </div>
        </div>
    );
}
