import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';

import "./NavigationDesktop.scss";
import { UserContext } from "../../contexts/UserContext";
import NavigationElement from "./NavigationElement";
import Separator from "./Separator";

interface LoggedOutNavigationDesktopProps {
    useClubDirectoryNav: boolean;
}

function LoggedOutNavigationDesktop(props: LoggedOutNavigationDesktopProps): JSX.Element {
    const { t } = useTranslation('layout');
    const { user } = useContext(UserContext);
                            
    return (
        <div className={'NavigationDesktop'}>
            {user === null && <>
                <div className="LoggedOut NavigationDesktop_Container">
                        <>
                           {/* register */}
                           <NavigationElement to={"/club-member/corpsite/link/launch"}>
                                {t('loggedOutNavigation.getStarted')}
                            </NavigationElement>
                            <Separator />
                            
                            {/* Login */}
                            <NavigationElement to={"/login"}>
                                {t('loggedOutNavigation.login')}
                            </NavigationElement>

                        </>

                </div>
            </>}
        </div>
    );
}

export default LoggedOutNavigationDesktop;
