import React from 'react';
import {useTranslation} from 'react-i18next';
import "../../../components/registrationWizard/steps/RegistrationWizardStep.scss";
import {FormControl} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {checkValidity, useCreateSubForm, SubFormContext} from "../../../util/Forms";
import {FirstNameInput} from "../../../components/registrationWizard/inputs/FirstNameInput";
import {LastNameInput} from "../../../components/registrationWizard/inputs/LastNameInput";
import {ContinueButton} from "../../../components/registrationWizard/inputs/ContinueButton";
import {StepProps} from "../../../util/registration-util";

export function MemberNameStep(props: StepProps): JSX.Element {
    const {t} = useTranslation('pages');
    const {elementConfig, context, formState} = useCreateSubForm();

    const continueEnabled = () => checkValidity({elementConfig, formState});

    return (
        <SubFormContext.Provider value={context}>
            <div className="RegistrationWizardStep MemberNameStep">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={2}/>
                    <Grid item xs={8}>
                        <Grid container spacing={1} direction="column" alignItems="stretch">
                            <Grid item>
                                <h2>{t('payerMemberRegistrationWizard.memberNameStep.namePrompt')}</h2>
                                <h4>{t('payerMemberRegistrationWizard.memberNameStep.noNicknameSubtext')}</h4>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <FirstNameInput/>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <LastNameInput/>
                                </FormControl>
                            </Grid>
                            <Grid item container justify="flex-end">
                                <FormControl>
                                    <ContinueButton
                                        onClick={props.onContinue}
                                        enabled={continueEnabled()}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}/>
                </Grid>
            </div>
        </SubFormContext.Provider>
    );
}