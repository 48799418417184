import React, {useEffect, useState} from "react";
import "./EventSearchResults.scss";
import { EventView } from "../findAnEvent/FindAnEventForm";
import {
    Instant,
    ZoneId
} from "@js-joda/core";
import i18n from "i18next";
import { getClubImageUrl } from "../../util/Util";
import {
    EventSearchResponseBody, EventSearchResultExtended,
    EventSearchSort
} from "../../pages/findAnEvent/FindAnEventPage";
import { EventType } from "../../util/EventType";
import ResultsList from "./ResultsList";
import ResultsMonthList from "./ResultsMonthList";
import ResultsCalendar from "./ResultsCalendar";
import ResultsTable from "./ResultsTable";

interface EventSearchResultProps {
    resultsLoading: boolean;
    searchResponseBody: EventSearchResponseBody | null;
    sortByEventStartsAt: () => void;
    sortByDistance: () => void;
    onSortSelected: (sort: EventSearchSort) => void;
    view: EventView;
    usingFilters: boolean;
    resetFilters: Function;
    viewVirtualOnly: Function;
    month: number;
    setMonth: (month: number) => void;
    maxEventsPerDay: number;
    scrollToEvent: (eventUrlFragment: string, startOfDate: string) => void;
}

export default function EventSearchResults(props: EventSearchResultProps) : JSX.Element {
    const {searchResponseBody} = props;
    const {month, setMonth} = props;

    const [results, setResults] = useState<EventSearchResultExtended[] | undefined>(undefined);

    useEffect(() => {
        const tempResults: EventSearchResultExtended[] | undefined = searchResponseBody?.results.map(result => {
            const params = new URLSearchParams();
            if (searchResponseBody !== null) {
                params.set("postalCode", searchResponseBody.postalCode)
            }

            const url = `/event/${encodeURIComponent(result.eventUrlFragment)}/search?${params.toString()}`;

            const eventStartsAtLocalDate = Instant.parse(result.eventStartsAt)
                .atZone(ZoneId.SYSTEM)
                .toLocalDate();
            const localDate = eventStartsAtLocalDate.toString();
            const today = eventStartsAtLocalDate
                .isEqual(Instant.now()
                    .atZone(ZoneId.systemDefault())
                    .toLocalDate());
            const eventStartsAtDate = new Date(result.eventStartsAt);
            const dayOfWeek = eventStartsAtDate
                .toLocaleDateString(i18n.language || 'en', { weekday: 'short' });
            const date = eventStartsAtDate
                .toLocaleDateString(i18n.language || 'en', { day: 'numeric', month: 'short' });
            const dateWithDayOfWeek = eventStartsAtDate
                .toLocaleDateString(i18n.language || 'en', { weekday: 'short', day: 'numeric', month: 'short' });
            const dayNumber = eventStartsAtDate
                .toLocaleDateString(i18n.language || 'en', { day: 'numeric' });
            const timeStart = eventStartsAtDate
                .toLocaleTimeString(i18n.language || 'en', {hour: 'numeric', minute: '2-digit'});
            const timeStartShort = eventStartsAtDate
                .toLocaleTimeString(i18n.language || 'en', {hour: 'numeric', timeZoneName: "short"});
            const timeEnd = new Date(result.eventEndsAt)
                .toLocaleTimeString(i18n.language || 'en', {hour: 'numeric', minute: '2-digit', timeZoneName: "short"});
            const monthRawNumber = new Date(Instant.parse(result.eventEndsAt).toEpochMilli()).getMonth();
            const passionImage = getClubImageUrl(result.passion);
            const passionImageUrl = passionImage.standard;
            const passionImageWideUrl = passionImage.wide;
            const passionImageThumbnailUrl = passionImage.thumbnail;
            const passionImageThumbnailWideUrl = passionImage.thumbnailWide;
            const isSelfGuided = result.eventTypeOne === EventType.SELF_GUIDED;
            return {
                ...result,
                ext: {
                    url,
                    passionImageUrl,
                    passionImageWideUrl,
                    passionImageThumbnailUrl,
                    passionImageThumbnailWideUrl,
                    today,
                    dayOfWeek,
                    dayNumber,
                    monthRawNumber,
                    date,
                    localDate,
                    dateWithDayOfWeek,
                    timeStart,
                    timeStartShort,
                    timeEnd,
                    isSelfGuided
                }
            }
        });
        setResults(tempResults);
    }, [searchResponseBody, searchResponseBody?.results]);

    return (
        <div className={`EventSearchResults ${props.view}`}>
            {searchResponseBody !== null && !!results && (
                <>
                    <ResultsTable
                        resultsLoading={props.resultsLoading}
                        results={results}
                        onSortSelected={props.onSortSelected}
                        searchResponseBody={searchResponseBody}
                        sortByEventStartsAt={props.sortByEventStartsAt}
                        sortByDistance={props.sortByDistance}
                        usingFilters={props.usingFilters}
                        resetFilters={props.resetFilters}
                        viewVirtualOnly={props.viewVirtualOnly}
                    />
                    <ResultsList
                        results={results}
                        onSortSelected={props.onSortSelected}
                        searchResponseBody={searchResponseBody}
                        sortByEventStartsAt={props.sortByEventStartsAt}
                        sortByDistance={props.sortByDistance}
                        usingFilters={props.usingFilters}
                        resetFilters={props.resetFilters}
                        viewVirtualOnly={props.viewVirtualOnly}
                    />
                    {/* Note: At the very least, ResultsCalendar has to always
                    be rendered as FullCalendar occasionally mounts/unmounts
                    badly otherwise */}
                    <ResultsCalendar
                        resultsLoading={props.resultsLoading}
                        searchResponseBody={searchResponseBody}
                        results={results}
                        month={month}
                        setMonth={setMonth}
                        usingFilters={props.usingFilters}
                        resetFilters={props.resetFilters}
                        maxEventsPerDay={props.maxEventsPerDay}
                        scrollToEvent={props.scrollToEvent}
                        viewVirtualOnly={props.viewVirtualOnly}
                    />
                    <ResultsMonthList
                        searchResponseBody={searchResponseBody}
                        results={results}
                        month={month}
                        setMonth={setMonth}
                        usingFilters={props.usingFilters}
                        resetFilters={props.resetFilters}
                        viewVirtualOnly={props.viewVirtualOnly}
                    />
                </>
            )}
        </div>
    );
};
