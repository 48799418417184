import React, {useState} from "react";
import { useTranslation } from 'react-i18next';

import "./PasswordResetForm.scss";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";
import {FormControl, TextField} from "@material-ui/core";
import {CCStyles} from "../form/Theme";
import {loadConfig} from "../../services/ConfigService";
import { validEmailRegexText } from "../../util/Util";
import {
    ACBL_LOGIN_FLOW_STORAGE_KEY,
    AcblLoginFlowData
} from "../../pages/acblRegistration/registrationWizard/AcblRegistrationWizard";

export default function PasswordResetForm(): JSX.Element {
    const { t } = useTranslation('passwordReset');

    const acblLoginFlowDataStr = localStorage.getItem(ACBL_LOGIN_FLOW_STORAGE_KEY)
    const acblLoginFlowData: AcblLoginFlowData | null = acblLoginFlowDataStr !== null
        ? JSON.parse(acblLoginFlowDataStr) : null;

    const [email, setEmail] = useState(acblLoginFlowData?.email || "");
    const [passwordResetResult, setPasswordResetResult] = useState("");
    const [loading, setLoading] = useState(false);

    const classes = CCStyles();

    function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    async function onSubmitPasswordResetForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        try {
            const config = await loadConfig();
            const request = new Request(`${config.apiOrigin}/password-reset-request`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email
                })
            });

            await fetch(request);

            setPasswordResetResult(t('passwordResetForm.success'));

            setLoading(false);
        } catch (e) {
            setLoading(false);
            setPasswordResetResult(t('passwordResetForm.error'));
            console.error("Error", e);
        }

    }

    return (
        <div className="PasswordResetForm">
            <div className="PasswordResetForm_Result">{passwordResetResult}</div>
            <form onSubmit={onSubmitPasswordResetForm}>
                <div className="PasswordResetForm_FieldContainer">
                    <div className="PasswordResetForm_Email">
                        <FormControl
                            className={`${classes.root} ${classes.formControl} `}
                            component="fieldset">
                            <TextField value={email}
                                       label={t('passwordResetForm.emailPlaceholder')}
                                       type="email"
                                       fullWidth={true}
                                       variant="outlined"
                                       className={`${classes.root}`}
                                       inputProps={{
                                           pattern: validEmailRegexText
                                       }}
                                       onChange={onChangeEmail} required autoFocus/>
                        </FormControl>
                    </div>
                </div>
                <div className="PasswordResetForm_ButtonContainer">
                    <PlayButton type="button" text={t('passwordResetForm.cancelButtonText')}
                                to="/login"
                                className="PasswordResetForm_CancelButton"
                                buttonStyle={ButtonStyles.FilledPrimary}/>
                    <PlayButton type="submit" text={t('passwordResetForm.submitButtonText')}
                                saving={loading}
                                className="PasswordResetForm_PasswordResetButton"
                                buttonStyle={ButtonStyles.FilledPrimary}/>
                </div>
            </form>
        </div>
    );
}
