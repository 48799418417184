import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';

import "./NavigationDesktop.scss";
import { UserContext } from "../../contexts/UserContext";
import NavigationElement from "./NavigationElement";
import NavigationDropdownElement from "./NavigationDropdownElement";
import Separator from "./Separator";
import { getIgniteRoute } from "../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../constants/routes";

interface NavigationDesktopProps {
    isLeaderView: boolean;
    currentClubId?: number;
    currentClubUrlFragment?: string;
    isClubIgniteEnabled: boolean;
    useClubDirectoryNav: boolean;
    useEdp44VirtualClubs: boolean;
}

function NavigationDesktop(props: NavigationDesktopProps): JSX.Element {
    const { t } = useTranslation('layout');
    const { user } = useContext(UserContext);

    const getRoute = (key: IGNITE_ROUTE_KEY) => props.isClubIgniteEnabled ?
        getIgniteRoute(key, { ':clubId': String(props.currentClubId) }) :
        getIgniteRoute(key, { ':clubId': String(user?.isIgniteEnabled?.clubId) });

    const dashboardRoute = getRoute(IGNITE_ROUTE_KEY.DASHBOARD)
    const leaderDashboardRoute = getRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD)
    const manageMembersRoute = getRoute(IGNITE_ROUTE_KEY.LEADER_MANAGE_MEMBERS)

    return (
        <div className={'NavigationDesktop'}>
            {user === null && <div />}
            {user != null && <>
                <div className="NavigationDesktop_Container">

                    {/* LEADER DASHBOARD */}
                    {props.isLeaderView && props.currentClubId && user?.isIgniteEnabled && <>
                        <NavigationElement to={leaderDashboardRoute}>
                            {t('navigation.toDoList')}
                        </NavigationElement>
                        <Separator />
                    </>}

                    {/* MANAGE CLUB */}
                    {props.isLeaderView && props.currentClubUrlFragment !== undefined && <>
                        <NavigationElement to={`/my-club/${props.currentClubUrlFragment}`}>{t('navigation.manageClub')}</NavigationElement>
                    </>}

                    {/* MANAGE MEMBERS */}
                    {props.isLeaderView && props.currentClubId && user?.isIgniteEnabled &&
                        <>
                            <Separator />
                            <NavigationElement to={manageMembersRoute}>
                                {t('navigation.manageMembers')}
                            </NavigationElement>
                        </>
                    }

                    {/* MEMBER DASHBOARD */}
                    {!props.isLeaderView && props.currentClubId && user?.isIgniteEnabled && <>
                        <NavigationElement to={dashboardRoute}>
                            {t('navigation.myActivity')}
                        </NavigationElement>
                        <Separator />
                    </>}

                    {/* MY SCHEDULE */}
                    {!props.isLeaderView && <>
                        <NavigationElement to={"/my-events"}>
                            {t('navigation.mySchedule')}
                        </NavigationElement>
                        <Separator />
                    </>}

                    {/* FIND AN ACTIVITY */}
                    {!props.isLeaderView && <>
                        <NavigationDropdownElement
                            title={t('navigation.findAClub')}
                            menuClassName="NavigationDesktopDropdown"
                            childURLs={["/virtual-clubs", "/find-an-event"]}
                        >
                            {props.useClubDirectoryNav ? (
                                <NavigationElement to={"/clubs"}  >
                                    {t('navigation.clubDirectory')}
                                </NavigationElement>
                            ) : (
                                <></>
                            )}
                            {
                                props.useEdp44VirtualClubs
                                    ? <NavigationElement to={"/virtual-clubs"}  >
                                        {t('navigation.virtualClubs')}
                                    </NavigationElement> 
                                    : <></>
                            }
                            <NavigationElement to={"/find-an-event"} >
                                {t('navigation.findAnActivity')}
                            </NavigationElement>
                        </NavigationDropdownElement>
                    </>}

                </div>
            </>}
        </div>
    );
}

export default NavigationDesktop;
